<template>
	<div class="w-full relative overflow-hidden">
	  <div
		class="flex transition-transform duration-300 ease-in-out"
		:style="{ transform: `translateX(-${currentIndex * 100}%)` }"
	  >
		<div v-for="i in products" :key="i" class="w-1/3 flex-none">
		  <div class="flex justify-center items-center h-80">
			<recommended-product-card 
			  :product_id="i" 
			  :user_type="user_type"
			/>
		  </div>
		</div>
	  </div>
  
	  <button
		class="absolute top-1/2 left-4 transform -translate-y-1/2 bg-white p-6 rounded-full"
		@click="prevSlide"
	  >
		<span class="text-2xl">&lt;</span>
	  </button>
	  <button
		class="absolute top-1/2 right-4 transform -translate-y-1/2 bg-white p-6 rounded-full"
		@click="nextSlide"
	  >
		<span class="text-2xl">&gt;</span>
	  </button>
	</div>
  </template>
  
  <script>
  import RecommendedProductCard from "./RecommendedProductCard";
  
  export default {
	name: "SimpleCarousel",
	components: {
	  RecommendedProductCard,
	},
	props: {
	  products: {
		type: Array,
		required: true,
	  },
	  user_type: {
		type: String,
		default: 'buyer',
	  },
	},
	data() {
	  return {
		currentIndex: 0,
	  };
	},
	methods: {
	  nextSlide() {
		if (this.currentIndex < this.products.length - 3) {
		  this.currentIndex++;
		} else {
		  this.currentIndex = 0;
		}
	  },
	  prevSlide() {
		if (this.currentIndex > 0) {
		  this.currentIndex--;
		} else {
		  this.currentIndex = this.products.length - 3;
		}
	  },
	},
  };
  </script>
  
  <style scoped>
  .carousel-container {
	width: 100%;
	overflow: hidden;
	position: relative;
  }
  
  .carousel-inner {
	display: flex;
	transition: transform 0.3s ease-in-out;
  }
  
  .carousel-cell {
	width: 33.33%;
	flex-shrink: 0;
	margin: 0;
	display: flex;
	flex-direction: column;
	justify-content: center; /* Center everything vertically */
	align-items: center;
	overflow: hidden;
  }
  
  ::v-deep .carousel-cell img {
	width: auto;
	max-width: 132%;
	height: auto;
	max-height: 230px;
	margin-left: -10px;
	object-fit: contain;
	margin-top: -45px; /* Adjusted to move image up */
	margin-bottom: 0; /* Remove extra spacing below image */
  }
  
  .recommended-product-card {
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: flex-start; /* Align content to the top */
	align-items: center;
	width: 100%;
  }
  
  .recommended-product-card .title {
	font-size: 1.5rem;

	margin-top: 1rem; /* Adjust the spacing to ensure it's balanced */
  }
  
  .recommended-product-card .price {
	font-size: 2rem;
	color: #073b7f;
	font-weight: bold;
	margin-left: 10px; 
	margin-top: 0.5rem;
  }
  
  
  
  
 
  
  

  
 
  
  
  .carousel-arrow {
	cursor: pointer;
	z-index: 10;
	font-size: 2rem; /* Increased the size of the arrow text */
	padding: 1.5rem; /* Increase padding for larger clickable area */
  }
  
  .carousel-arrow.prev {
	left: 10px;
  }
  
  .carousel-arrow.next {
	right: 10px;
  }
  </style>
  